import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import { FORMS_STORE_NAME } from '@nickel/forms/redux/constants'

import { version } from '../../../package.json'

const REDUX_PERSIST = {
    reducerVersion: version,
    rootConfig: {
        key: 'starter-react',
        storage,
        whitelist: [
            'app',
            'auth',
            'mail',
            'documents',
            'profession',
            'finalization',
            'guardianTerms',
            'identityDocuments',
            'liveness',
            'mailValidation',
            'ocr',
            'registration',
            'reopening',
            'reopeningKyc',
            'tracking',
            'bankCardInfo',
            'eidPdfDocuments',
            'taxResidences',
            FORMS_STORE_NAME
        ]
    }
}

export default REDUX_PERSIST
