import { getCurrentLng } from '@nickel/i18n'
import { getGlobalISO2 } from '@nickel/l10n/config'
import { push as pushToGtm } from '@nickel/tracking/gtm'

import { TrackEvent, TrackEventInfo } from '../redux/tracking'

const POPUP_TRACKING_EVENT = 'popup_triggered'

export enum POPUP_CONTEXT {
    adultId = 'ERROR - OCR - Adult document',
    assetsTooLow = 'INFO -  KYC inconsistency - Owner',
    birthCountry = 'INFO - KYC inconsistency - Country birth is not nationality',
    defaultError = 'ERROR',
    documentRefused = 'ERROR - OCR - Forbidden document',
    documentUnrecognized = 'ERROR - OCR - Document not recognized',
    emptyMail = 'INFO - Continue without mail',
    expiredId = 'ERROR - OCR - Expired document',
    fatca = 'INFO - FATCA to provide later',
    finalControl = 'ERROR - subscription integrity check fail',
    missingNIF = 'INFO - NIF to provide later',
    oldEmployment = 'INFO - KYC inconsistency - Old not retired',
    retiredIncome = 'INFO -  KYC inconsistency - Retired',
    studentAge = 'INFO -  KYC inconsistency - Student too old',
    studentIncome = 'INFO -  KYC inconsistency - Student',
    timeout = 'ERROR - Timeout',
    youngId = 'ERROR - OCR - Young document',
    isNotUeAele = 'ERROR - Selected country is not UE/AELE'
}

export const pushPopupTriggeredToGtm = (context: POPUP_CONTEXT = POPUP_CONTEXT.defaultError) => {
    pushToGtm({
        event: POPUP_TRACKING_EVENT,
        popup_context: context
    })
}

export const pushTrackEventToDatalayer = (trackEvent: TrackEvent, value: string | number | null | undefined) => {
    pushToGtm({
        [trackEvent]: value || ''
    })
}

export const trackEventInfo = (eventInfo: TrackEventInfo) => {
    switch (eventInfo.event) {
        case TrackEvent.DOCUMENT_TYPE:
        case TrackEvent.DOCUMENT_TYPE_GUARDIAN: {
            pushToGtm({
                [TrackEvent.DOCUMENT_TYPE]: eventInfo.info
            })
            break
        }
        case TrackEvent.DOCUMENT_TYPE_CNJ: {
            pushToGtm({
                [TrackEvent.DOCUMENT_TYPE_CNJ]: eventInfo.info
            })
            break
        }
        default: {
            break
        }
    }
}

export const trackCountryAndLocale = () => {
    pushToGtm({
        [TrackEvent.COUNTRY]: getGlobalISO2()
    })
    pushToGtm({
        [TrackEvent.LOCALE]: getCurrentLng()
    })
}
