export enum Step {
    ACCOUNT_SUMMARY = 'account-summary',
    ACCOUNT_TYPE = 'account-type',
    ACCOUNT_USE = 'account-use',
    ACCOUNT_VALIDATION = 'account-validation',
    ADDRESS = 'address',
    ADDRESS_CHILD = 'address-child',
    ADDRESS_GUARDIAN = 'address-guardian',
    ALL_ELEMENTS = 'all-elements',
    BANK = 'bank',
    BANK_CARD_CUSTOMIZATION = 'bank-card-customization',
    BANK_CARD_TYPE = 'bank-card-type',
    BARCODE = 'barcode',
    BIOMETRIC_CONSENT = 'biometric-consent',
    BIRTH_DATA_CONFIRMATION = 'birth-data-confirmation',
    BIRTH_DATA_CONFIRMATION_CHILD = 'birth-data-confirmation-child',
    BIRTH_DATA_CONFIRMATION_GUARDIAN = 'birth-data-confirmation-guardian',
    CARD_RECEPTION_MODE = 'card-reception-mode',
    CHOICE = 'choice',
    CHOICE_CHILD = 'choice-child',
    CHOICE_GUARDIAN = 'choice-guardian',
    CIVILITY_CONFIRMATION = 'civility-confirmation',
    CIVILITY_CONFIRMATION_CHILD = 'civility-confirmation-child',
    CIVILITY_CONFIRMATION_GUARDIAN = 'civility-confirmation-guardian',
    COUNTRY = 'country',
    CUSTOMER_SUPPORT = 'customer-support',
    DOCUMENT = 'document',
    DOCUMENT_CHILD = 'document-child',
    DOCUMENT_GUARDIAN = 'document-guardian',
    EMPLOYMENT = 'employment',
    FALLBACK_CARD_TYPE = 'fallback-card-type',
    FAMILY_REGISTER = 'family-register',
    FAMILY_STATUS = 'family-status',
    FINAL = 'final',
    FINAL_STEP = 'final-step',
    FINANCIAL = 'financial',
    FISCAL = 'fiscal',
    FISCAL_ADDRESS = 'fiscal-address',
    GUARDIAN = 'guardian',
    HOSTED = 'HOSTED',
    IDENTITY = 'identity',
    LIVENESS = 'liveness',
    LIVENESS_CHILD = 'liveness-child',
    LIVENESS_GUARDIAN = 'liveness-guardian',
    LIVENESS_VIDEO = 'liveness-video',
    MAIL = 'mail',
    MANDATORY_EMAIL = 'mandatory-email',
    ORDER_SUMMARY = 'order-summary',
    PAYMENT = 'payment',
    PERSONAL_DATA = 'personal-data',
    PHONE = 'phone',
    PRIVACY_POLICY_EID = 'privacy-policy-eid',
    PRIVATE_LIFE_CERTIFICATION = 'private-life-certification',
    PROPERTY_STATUS = 'property-status',
    REGISTRATION_TYPE = 'registration-type',
    REOPENING = 'reopening',
    REOPENING_CHOICE = 'reopening-choice',
    REOPENING_IDENTIFICATION = 'reopening-identification',
    REOPENING_INFO = 'search',
    REOPENING_KYC = 'reopening-kyc',
    REOPENING_MATCH_MULTY = 'match-multy',
    REOPENING_MATCH_ONE = 'match-one',
    REOPENING_MATCH_ZERO = 'match-zero',
    SIGNING = 'signing',
    SMS_CHALLENGE = 'sms-challenge',
    TAX_IDENTIFICATION_NUMBER = 'tax-identification-number',
    TAX_RESIDENCE = 'tax-residence',
    TAX_RESIDENCE_IN_PROGRESS = 'tax-residence-in-progress',
    TAX_RESIDENCE_COUNTRY = 'tax-residence-country',
    TAX_RESIDENCE_ADDRESS = 'tax-residence-address',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
    TERMS_AND_CONDITIONS_EID = 'terms-and-conditions-eid',
    US_PERSON = 'us-person'
}
