import { lazy as _lazy } from 'react'

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const lazy = (importStatement: () => Promise<any>) => {
    return { ..._lazy(importStatement), preload: importStatement }
}

const AccountUse = lazy(() => import(/* webpackChunkName: "AccountUse" */ '../screens/AccountUse'))
const Address = lazy(() => import(/* webpackChunkName: "Address" */ '../screens/Address'))
const AllElements = lazy(() => import(/* webpackChunkName: "AllElements" */ '../screens/AllElements'))
const BankCardCustomization = lazy(
    () => import(/* webpackChunkName: "BankCardCustomization" */ '../screens/BankCardCustomization')
)
const BankCardType = lazy(() => import(/* webpackChunkName: "BankCardType" */ '../screens/BankCardType'))
const Barcode = lazy(() => import(/* webpackChunkName: "Barcode" */ '../screens/Barcode'))
const BirthDataConfirmation = lazy(
    () => import(/* webpackChunkName: "BirthDataConfirmation" */ '../screens/BirthDataConfirmation')
)
const CardReceptionMode = lazy(() => import(/* webpackChunkName: "CardReceptionMode" */ '../screens/CardReceptionMode'))
const BiometricConsent = lazy(() => import(/* webpackChunkName: "BiometricConsent" */ '../screens/BiometricConsent'))
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Civil = lazy(() => import(/* webpackChunkName: "Civil" */ '../screens/Civil'))
const CustomerSupport = lazy(() => import(/* webpackChunkName: "CustomerSupport" */ '../screens/CustomerSupport'))
const Employment = lazy(() => import(/* webpackChunkName: "Employment" */ '../screens/Employment'))
const MandatoryEmail = lazy(() => import(/* webpackChunkName: "MandatoryEmail" */ '../screens/MandatoryEmail'))
const FamilyRegister = lazy(() => import(/* webpackChunkName: "FamilyRegister" */ '../screens/FamilyRegister'))
const FamilyStatus = lazy(() => import(/* webpackChunkName: "FamilyStatus" */ '../screens/FamilyStatus'))
const Final = lazy(() => import(/* webpackChunkName: "Final" */ '../screens/Final'))
const Financial = lazy(() => import(/* webpackChunkName: "Financial" */ '../screens/Financial'))
const FiscalAddress = lazy(() => import(/* webpackChunkName: "FiscalAddress" */ '../screens/FiscalAddress'))
const Guardian = lazy(() => import(/* webpackChunkName: "Guardian" */ '../screens/Guardian'))
const IdentityChoice = lazy(() => import(/* webpackChunkName: "IdentityChoice" */ '../screens/IdentityChoice'))
const IdentityDocument = lazy(() => import(/* webpackChunkName: "IdentityDocument" */ '../screens/IdentityDocument'))
const Liveness = lazy(() => import(/* webpackChunkName: "Liveness" */ '../screens/Liveness'))
const LivenessVideo = lazy(() => import('../screens/LivenessVideo'))
const Mail = lazy(() => import(/* webpackChunkName: "Mail" */ '../screens/Mail'))
const OrderSummary = lazy(() => import(/* webpackChunkName: "OrderSummary" */ '../screens/OrderSummary'))
const Payment = lazy(() => import(/* webpackChunkName: "Payment" */ '../screens/Payment'))
const Phone = lazy(() => import(/* webpackChunkName: "Phone" */ '../screens/Phone'))
const PrivateLifeCertification = lazy(
    () => import(/* webpackChunkName: "PrivateLifeCertification" */ '../screens/PrivateLifeCertification')
)
const PropertyStatus = lazy(() => import(/* webpackChunkName: "PropertyStatus" */ '../screens/PropertyStatus'))
const RegistrationType = lazy(() => import(/* webpackChunkName: "RegistrationType" */ '../screens/RegistrationType'))
const Reopening = lazy(() => import(/* webpackChunkName: "Reopening" */ '../screens/Reopening'))
const ReopeningChoice = lazy(() => import(/* webpackChunkName: "ReopeningChoice" */ '../screens/ReopeningChoice'))
const ReopeningInfo = lazy(() => import(/* webpackChunkName: "ReopeningInfo" */ '../screens/ReopeningInfo'))
const ReopeningKyc = lazy(() => import(/* webpackChunkName: "ReopeningKyc" */ '../screens/ReopeningKyc'))
const ReopeningMatchMulty = lazy(
    () => import(/* webpackChunkName: "ReopeningMatchMulty" */ '../screens/ReopeningMatchMulty')
)
const ReopeningMatchOne = lazy(() => import(/* webpackChunkName: "ReopeningMatchOne" */ '../screens/ReopeningMatchOne'))
const ReopeningMatchZero = lazy(
    () => import(/* webpackChunkName: "ReopeningMatchZero" */ '../screens/ReopeningMatchZero')
)
const SMSChallenge = lazy(() => import(/* webpackChunkName: "SMSChallenge" */ '../screens/SMSChallenge'))
const TaxResidence = lazy(() => import(/* webpackChunkName: "TaxResidence" */ '../screens/TaxResidence'))
const TaxResidenceCountry = lazy(() => import(/* webpackChunkName: "TaxResidence" */ '../screens/TaxResidenceCountry'))
const TaxResidenceAddress = lazy(
    () => import(/* webpackChunkName: "TaxResidenceAddress" */ '../screens/TaxResidenceAddress')
)
const TermsAndConditions = lazy(
    () => import(/* webpackChunkName: "TermsAndConditions" */ '../screens/TermsAndConditions')
)
const UsPerson = lazy(() => import(/* webpackChunkName: "UsPerson" */ '../screens/UsPerson'))
const AccountSummary = lazy(() => import(/* wabpackChunkName: "AccountSummary" */ '../screens/AccountSummary'))
const AccountValidation = lazy(() => import(/* webpackChunkName: "AccountValidation" */ '../screens/AccountValidation'))
const AccountType = lazy(() => import(/* webpackChunkName: "AccountType" */ '../screens/AccountType'))
const Country = lazy(() => import(/* webpackChunkName: "Country" */ '../screens/Country'))
const TermsAndConditionsEid = lazy(
    () => import(/* webpackChunkName: "TermsAndConditionsEid" */ '../screens/TermsAndConditionsEid')
)
const PrivacyPolicyEid = lazy(() => import(/* webpackChunkName: "PrivacyPolicyEid" */ '../screens/PrivacyPolicyEid'))
const TaxIdentificationNumber = lazy(
    () => import(/* webpackChunkName: "TaxIdentificationNumber" */ '../screens/TaxIdentificationNumber')
)
const TaxResidenceInProgress = lazy(
    () => import(/* webpackChunkName: "TaxResidenceInProgress" */ '../screens/TaxResidenceInProgress')
)

export default {
    AccountSummary,
    AccountType,
    AccountUse,
    AccountValidation,
    Address,
    AllElements,
    BankCardCustomization,
    BankCardType,
    Barcode,
    BiometricConsent,
    BirthDataConfirmation,
    CardReceptionMode,
    Civil,
    Country,
    CustomerSupport,
    Employment,
    FamilyRegister,
    FamilyStatus,
    Final,
    Financial,
    FiscalAddress,
    Guardian,
    IdentityChoice,
    IdentityDocument,
    Liveness,
    LivenessVideo,
    Mail,
    MandatoryEmail,
    OrderSummary,
    Payment,
    Phone,
    PrivacyPolicyEid,
    PrivateLifeCertification,
    PropertyStatus,
    RegistrationType,
    Reopening,
    ReopeningChoice,
    ReopeningInfo,
    ReopeningKyc,
    ReopeningMatchMulty,
    ReopeningMatchOne,
    ReopeningMatchZero,
    SMSChallenge,
    TaxIdentificationNumber,
    TaxResidence,
    TaxResidenceInProgress,
    TaxResidenceCountry,
    TaxResidenceAddress,
    TermsAndConditions,
    TermsAndConditionsEid,
    UsPerson
}
