import React, { useMemo } from 'react'

import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FontWeight } from '@nickel/foundations/tokens/typography'

import { selectors as registrationTypeSelectors } from '../../redux/form/registrationType'
import { selectors as currentRoleSelectors } from '../../redux/navigation/currentRole'

import Styled from './style'

type Props = {
    fontWeight?: FontWeight
    i18nKey: string
    testId?: string
    uppercase?: boolean
    useCurrentRegistrationType?: boolean
    useCurrentRole?: boolean
    values?: Record<string, string | undefined>
    withNoWrap?: boolean
}

const Title = ({
    fontWeight = FontWeight.BOLD,
    i18nKey,
    testId,
    uppercase = false,
    useCurrentRegistrationType = false,
    useCurrentRole = false,
    values,
    withNoWrap = true,
    ...props
}: Props) => {
    const currentRole = useSelector(currentRoleSelectors.get)
    const registrationType = useSelector(registrationTypeSelectors.getRegistrationType)

    const context = useMemo(() => {
        if (useCurrentRegistrationType) return registrationType
        if (useCurrentRole) return currentRole
    }, [currentRole, registrationType, useCurrentRegistrationType, useCurrentRole])

    return (
        <Styled.H1
            {...props}
            fontWeight={fontWeight}
            uppercase={uppercase}
            withNoWrap={withNoWrap}
            {...(testId && { 'data-testid': testId })}
        >
            <Trans i18nKey={i18nKey} tOptions={{ context }} values={values}>
                <span>text_0</span>
            </Trans>
        </Styled.H1>
    )
}

export default Title
