import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { isSet } from '@nickel/utils/lib/common'

import { GetAllowedCountriesCountriesEnum } from '../../services'

export enum FeatureFlag {
    CNJ = 'CNJ',
    REOPENING = 'REOPENING',
    REOPENING_MANUAL = 'REOPENING_MANUAL',
    CROWDIN_IN_CONTEXT = 'CROWDININCONTEXT',
    FULL_ONLINE = 'FULL_ONLINE',
    CAPTCHA = 'CAPTCHA',
    AEOI = 'AEOI'
}

export const getEnabledSyncFeatures = (): string[] => {
    let features = window.REACT_APP_FEATURES || ''
    if (window.Cypress && isSet(window.Cypress.env('REACT_APP_FEATURES'))) {
        features = window.Cypress.env('REACT_APP_FEATURES')
    }
    return features.split(',')
}

export const isSyncFeatureEnabled = (
    flag: FeatureFlag,
    country: GetAllowedCountriesCountriesEnum | SupportedCountryISO2
): boolean => {
    const features = getEnabledSyncFeatures()
    return features.includes(flag) || features.includes(`${flag}_${country}`.toUpperCase())
}
