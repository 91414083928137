import { actions } from '../../redux/form/taxResidence'
import { actions as navigationActions } from '../../redux/navigation'

export const hasOnlySelectCurrentCountryModalData = {
    title: 'tax-residence:modals.hasOnlySelectCurrentCountry.title',
    message: 'tax-residence:modals.hasOnlySelectCurrentCountry.message',
    primaryAction: {
        text: 'tax-residence:modals.hasOnlySelectCurrentCountry.primary-action',
        action: actions.confirmTaxResidenceInCurrentCountry()
    },
    secondaryAction: {
        text: 'tax-residence:modals.hasOnlySelectCurrentCountry.secondary-action',
        action: actions.cancelConfirmationCurrentCountryTaxResidence()
    }
}

export const blankTaxResidenceIdentificationReminderModalData = {
    title: 'tax-residence:modals.blankTaxResidenceIdentificationReminderModalData.title',
    message: 'tax-residence:modals.blankTaxResidenceIdentificationReminderModalData.message',
    primaryAction: {
        text: 'tax-residence:modals.blankTaxResidenceIdentificationReminderModalData.primary-action',
        action: navigationActions.goToNextStep()
    },
    secondaryAction: {
        text: 'tax-residence:modals.blankTaxResidenceIdentificationReminderModalData.secondary-action'
    }
}

export const inconsistentWithRegistrationTypeCnjModalData = {
    title: 'tax-residence:modals.inconsistentWithRegistrationTypeCnjModalData.title',
    message: 'tax-residence:modals.inconsistentWithRegistrationTypeCnjModalData.message',
    primaryAction: {
        text: 'tax-residence:modals.inconsistentWithRegistrationTypeCnjModalData.primary-action',
        action: navigationActions.goToFirstStep()
    },
    secondaryAction: {
        text: 'tax-residence:modals.inconsistentWithRegistrationTypeCnjModalData.secondary-action'
    }
}

export const whatTaxIdentificationNumberModalData = {
    title: 'tax-residence:modals.whatTaxIdentificationNumberModalData.title',
    message: 'tax-residence:modals.whatTaxIdentificationNumberModalData.message',
    primaryAction: {
        text: 'common:understood'
    }
}

export const whenFindMyTaxNumberModalData = {
    title: 'tax-residence:modals.whenFindMyTaxNumberModalData.title',
    message: 'tax-residence:modals.whenFindMyTaxNumberModalData.message',
    primaryAction: {
        text: 'common:understood'
    }
}
export const getCantHaveOtherTaxResidenceModalData = (homeLocation: string) => ({
    message: 'tax-residence:modals.cantHaveOtherTaxResidenceModalData.message',
    primaryAction: {
        text: 'common:quit-all',
        action: navigationActions.goToExternal(homeLocation)
    },
    showCloseButton: true
})
