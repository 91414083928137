import styled from 'styled-components'

import { References, Systems } from '@nickel/foundations/tokens/color'
import { Spacing } from '@nickel/foundations/tokens/layout'

const Header = styled.header`
    align-items: center;
    background-color: ${Systems.SYS_BACKGROUND_DEFAULT};
    border-bottom: 1px solid ${References.REF_NEUTRAL_N20};
    box-shadow: 0 0 1rem rgb(204, 204, 204);
    display: flex;
    height: 4em;
    justify-content: space-between;
    padding: 0 ${Spacing.LARGE};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;

    img {
        height: inherit;
        justify-self: center;
    }

    > :last-child {
        justify-self: right;
    }
`

const LogoPT = styled.header`
    height: 80%;
    justify-self: center;
`

export default {
    Header,
    LogoPT
}
