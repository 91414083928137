export enum CardReceptionModeEnum {
    CLASSIC = 'CLASSIC',
    FULL_ONLINE = 'FULL_ONLINE'
}

export enum OcrEventTypeEnum {
    ONFIDO = 'ONFIDO',
    EID = 'EID'
}

export interface CardReceptionModeFormInputs {
    cardReceptionMode: CardReceptionModeEnum
}
