import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { complement, isNil } from 'ramda'

import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { isSet } from '@nickel/utils/lib/common'

import {
    getTaxIdValue,
    isTaxResidenceWhichTriggersBlockingAEOIInconsistency
} from '../../components/TaxIdentificationNumberForm/utils'
import { UpdatePostalAddressPayload } from '../../services'
import { selectors as countrySelectors } from '../form/country'
import { RootState } from '../index'
import { actions as registrationActions } from '../registration'

export type TaxResidencesInfo = {
    taxResidences: TaxResidence[]
    taxAddress: TaxAddress
}
export type TaxResidence = {
    country: string
    taxIdentificationNumber?: string
    startedObtainingTaxResidency?: boolean
    taxResidenceAddedFromTaxResidenceInProgress?: boolean
}
export interface TaxAddress {
    country?: string
    streetNumber?: string
    streetName?: string
    additionalAddress?: string
    locality?: string
    postalCode?: string
}

export const emptyTaxResidence: TaxResidence = {
    country: '',
    taxIdentificationNumber: '',
    startedObtainingTaxResidency: false,
    taxResidenceAddedFromTaxResidenceInProgress: false
}

export const INITIAL_STATE: TaxResidencesInfo = {
    taxResidences: [emptyTaxResidence],
    taxAddress: { country: '', streetNumber: '', streetName: '', additionalAddress: '', locality: '', postalCode: '' }
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@tax-residences'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        updateTaxResidence: (state, { payload }: PayloadAction<TaxResidence>) => {
            const shouldUpdate = state.taxResidences.some((taxResidence) => taxResidence.country === payload.country)
            if (shouldUpdate) {
                return {
                    ...state,
                    taxResidences: state.taxResidences.map((taxResidence) =>
                        taxResidence.country === payload.country ? payload : taxResidence
                    )
                }
            }
            return {
                ...state,
                taxResidences: [...state.taxResidences.filter((taxResidence) => !!taxResidence.country), payload]
            }
        },
        updateTaxResidences: (state, { payload }: PayloadAction<TaxResidence[]>) => ({
            ...state,
            taxResidences: cloneDeep(payload)
        }),
        updateTaxAddress: (state, { payload }: PayloadAction<TaxAddress>) => ({
            ...state,
            taxAddress: cloneDeep(payload)
        }),
        updateStartedObtainingTaxResidency: (
            state,
            {
                payload: { country, startedObtainingTaxResidency, taxResidenceAddedFromTaxResidenceInProgress }
            }: PayloadAction<{
                country: string
                startedObtainingTaxResidency: boolean
                taxResidenceAddedFromTaxResidenceInProgress: boolean
            }>
        ) => ({
            ...state,
            taxResidences: [
                {
                    country,
                    taxIdentificationNumber:
                        state.taxResidences.find((residence) => residence.country === country)
                            ?.taxIdentificationNumber ?? '',
                    startedObtainingTaxResidency,
                    taxResidenceAddedFromTaxResidenceInProgress
                },
                ...state.taxResidences.filter((residence) => residence.country !== country)
            ]
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, () => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getTaxIdentificationPayload = (state: RootState) => {
    return {
        taxResidences: (state.taxResidences as TaxResidencesInfo).taxResidences
            .filter((residence) => complement(isNil)(residence.country))
            .map(({ country, taxIdentificationNumber, startedObtainingTaxResidency }) => ({
                countryCode: country,
                taxIdentificationNumber: taxIdentificationNumber
                    ? getTaxIdValue(country, taxIdentificationNumber)
                    : undefined,
                startedObtainingTaxResidency
            }))
    }
}

const getTaxResidenceAddressPayload = (state: RootState) => {
    const {
        taxResidences: { taxAddress }
    } = state
    return {
        additionalAddress: taxAddress?.additionalAddress,
        countryCode: taxAddress?.country,
        place: taxAddress?.locality,
        streetNumber: taxAddress?.streetNumber,
        streetName: taxAddress?.streetName,
        zipCode: taxAddress?.postalCode
    } as UpdatePostalAddressPayload
}

const getTaxResidences = (state: RootState) => (state.taxResidences as TaxResidencesInfo).taxResidences
const getTaxAddress = (state: RootState) => (state.taxResidences as TaxResidencesInfo).taxAddress
const getTaxResidencesInfo = (state: RootState) => state.taxResidences

const getTaxResidencesThatNeedsANif = createSelector(
    [getTaxResidences, countrySelectors.getCountry],
    (taxResidences, registrationCountry) => {
        return taxResidences
            .filter(
                (residence) =>
                    isTaxResidenceWhichTriggersBlockingAEOIInconsistency(
                        registrationCountry as SupportedCountryISO2,
                        residence.country,
                        taxResidences.length
                    ) && !isSet(residence.taxIdentificationNumber)
            )
            .map((residence) => residence.country)
    }
)

const getIsStartedObtainingTaxResidency = (registrationCountry: string) => (state: RootState) =>
    (state.taxResidences as TaxResidencesInfo).taxResidences.find(({ country }) => country === registrationCountry)
        ?.startedObtainingTaxResidency

const getTaxResidencesCountries = createSelector([getTaxResidences], (taxResidences) => {
    return taxResidences.map((residence) => residence.country)
})

const getHasMultipleTaxResidence = createSelector([getTaxResidences], (taxResidences) => {
    if (isNil(taxResidences)) return
    return taxResidences.length > 1
})

export const selectors = {
    getTaxIdentificationPayload,
    getTaxResidences,
    getTaxAddress,
    getTaxResidencesInfo,
    getTaxResidencesThatNeedsANif,
    getTaxResidencesCountries,
    getIsStartedObtainingTaxResidency,
    getTaxResidenceAddressPayload,
    getHasMultipleTaxResidence
}
