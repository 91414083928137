import styled from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize } from '@nickel/foundations/tokens/typography'

const BarcodeHelperContent = styled.img`
    max-width: 18em;
    width: 100%;
`

const FamilyRegisterContent = styled.div`
    text-align: center;

    img {
        margin-bottom: ${Spacing.LARGE};
        max-width: 100%;

        @media (min-width: ${Breakpoint.LARGE}) {
            max-width: 25rem;
        }
    }
`

const IdentityContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    @media (min-width: ${Breakpoint.LARGE}) {
        align-items: baseline;
        flex-direction: row;
    }

    div {
        flex-basis: 30%;
        margin: 0 1rem 1rem 1rem;
        @media (min-width: ${Breakpoint.LARGE}) {
            max-width: 30%;
        }

        img {
            margin-bottom: 1rem;
            max-height: 20rem;
            max-width: 100%;
        }
    }

    p {
        margin: 0;
    }

    .aele-countries {
        font-size: ${FontSize.SMALL};
    }
`

const Image = styled.div`
    margin: ${Spacing.MEDIUM} ${Spacing.SMALL};
    position: relative;

    > div > img {
        filter: brightness(60%);
        max-width: 300px;
        overflow: hidden;
        width: 100%;
    }

    > div::after {
        background-color: rgba(255, 255, 255, 0.8);
        border: 2px solid ${References.REF_BRAND_B100};
        border-radius: 1rem;
        font-size: 11px;
        padding: 0 5px;
        position: absolute;
    }

    &:nth-child(1) > div::after {
        content: 'XXXXX XXX XX';
        left: 41%;
        top: 17%;
    }

    &:nth-child(2) > div::after {
        content: 'XXXXXXXX';
        left: 72%;
        top: 13%;
    }

    &:nth-child(3) > div::after {
        content: 'XXXXXXXX';
        left: 76%;
        top: 3%;
    }
`

const ImageWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
`

const RgdpContent = styled.div`
    height: calc(100vw - 12rem);
    width: calc(100vw - 12rem);
`

interface RgpdFramProps {
    readonly isVisible: boolean
}

const RgdpFrame = styled.iframe<RgpdFramProps>`
    height: 100%;
    width: 100%;
    ${({ isVisible }) => (isVisible ? undefined : 'display: none;')}
`

const ScannerContent = styled.div`
    text-align: left;

    img,
    span {
        display: inline-block;
        margin-bottom: 2em;
        vertical-align: middle;
    }

    img {
        width: 30%;
    }

    span {
        margin-left: 5%;
        width: 65%;
    }
`

export default {
    BarcodeHelperContent,
    FamilyRegisterContent,
    IdentityContent,
    Image,
    ImageWrapper,
    RgdpContent,
    RgdpFrame,
    ScannerContent
}
