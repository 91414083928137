import styled from 'styled-components'

import Button from '@nickel/core-ui/components/Button'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'

const CenteredButton = styled(Button)`
    margin: ${Spacing.LARGE} auto;
`

const Container = styled.div<{ isFullWidth?: boolean; maxWidth?: string }>`
    margin: 0 auto;
    padding: ${Spacing.XXLARGE} ${Spacing.LARGE} calc(${Spacing.XXLARGE} * 3);
    ${({ isFullWidth, maxWidth }) => (isFullWidth ? 'width: 100%;' : `max-width: ${maxWidth || '50em'};`)}

    @media (min-width: ${Breakpoint.MEDIUM}) {
        padding: ${Spacing.XWIDE} ${Spacing.XWIDE} 0 ${Spacing.XWIDE};
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${Spacing.XXLARGE};

    * {
        margin: auto;
    }

    > :not(:first-child) {
        margin-top: ${Spacing.XLARGE};
    }
`

const ContextDescription = styled.div`
    margin-bottom: 1rem;
    text-align: center;
`

const ImageInputsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > * {
        flex: 1;
        margin: ${Spacing.LARGE};
    }
`

export const TitleContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: ${Spacing.XXLARGE} 0 3.5rem;
    padding-top: ${Spacing.XXLARGE};
    text-align: center;
    @media (max-width: ${Breakpoint.MEDIUM}) {
        margin: ${Spacing.XLARGE} 0 ${Spacing.XXLARGE};
    }
`

export default {
    ButtonsWrapper,
    CenteredButton,
    Container,
    ContextDescription,
    ImageInputsWrapper,
    TitleContainer
}
