import styled from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize, FontWeight } from '@nickel/foundations/tokens/typography'

const H1 = styled.h1<{ fontWeight: FontWeight; uppercase?: boolean; withNoWrap?: boolean }>`
    font-size: ${FontSize.XWIDE};
    font-weight: ${({ fontWeight }) => fontWeight};
    margin-bottom: ${Spacing.XLARGE};
    text-align: center;
    ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}

    span {
        color: ${References.REF_BRAND_B100};
        ${({ withNoWrap }) => withNoWrap && 'white-space: nowrap;'}
    }

    @media (min-width: ${Breakpoint.MEDIUM}) {
        font-size: ${FontSize.XWIDE};
    }

    @media (min-width: ${Breakpoint.MEDIUM}) {
        margin-bottom: 2.5rem;
    }
`

export default {
    H1
}
