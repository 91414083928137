import styled from 'styled-components'

import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'

import ProgressCircleComponent from '../../components/ProgressCircle'

const ProgressCircle = styled(ProgressCircleComponent)`
    margin-top: ${Spacing.XXLARGE};

    @media (max-width: ${Breakpoint.MEDIUM}) {
        svg {
            height: 40%;
            width: 40%;
        }
    }
`

export default {
    ProgressCircle
}
