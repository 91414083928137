import React, { useCallback, useEffect } from 'react'

import { startsWith } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Text from '@nickel/core-ui/components/Text'
import { TextStyle } from '@nickel/core-ui/components/Text/types'
import { FontWeight } from '@nickel/foundations/tokens/typography'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import Loader from '@nickel/ui/components/legacy/Loader'
import { isTerminal } from '@nickel/utils/lib/web'

import Captcha from '../../components/Captcha'
import NextButton from '../../components/NextButton'
import Title from '../../components/Title'
import { FeatureFlag, isSyncFeatureEnabled } from '../../config/features'
import CountryField from '../../fields/Country'
import LanguageField from '../../fields/Language'
import useAllowedCountries from '../../hooks/useAllowedCountries'
import useCaptcha from '../../hooks/useCaptcha'
import useNavigation from '../../hooks/useNavigation'
import { getAllowedCountryISO2, getSupportedCountryISO2 } from '../../l10n/utils'
import { DefaultSupportedLocalePath, SupportedLocalePath } from '../../navigation/types'
import { selectors as authSelectors } from '../../redux/auth'
import { selectors as countrySelectors } from '../../redux/form/country'
import { actions as registrationActions } from '../../redux/registration'
import { GetAllowedCountriesCountriesEnum } from '../../services'
import ScreenStyled from '../style'

import CrowdinContextButton from './crowdinContextButton'
import useLangage from './hooks/useLangage'
import useMounted from './hooks/useMounted'
import Styled from './style'

const Country = () => {
    const { t } = useTranslation()
    const mounted = useMounted()
    const dispatch = useDispatch()
    const { languages, language } = useLangage()
    const selectedCountry = useSelector(countrySelectors.getCountry)
    const registrationFormId = useSelector(authSelectors.getRegistrationFormId)
    const isInitializing = useSelector(authSelectors.getIsInitializing)
    const { executeCaptchaChallenge, resetCaptchaChallenge } = useCaptcha()

    const { allowedCountries } = useAllowedCountries()
    const { goToStep, goToNextStep } = useNavigation()

    const onNextButtonClick = useCallback(() => {
        executeCaptchaChallenge()
    }, [executeCaptchaChallenge])

    const handleCaptchaChallengeCompleted = useCallback(() => {
        dispatch(registrationActions.init({ country: selectedCountry, language }))
    }, [dispatch, language, selectedCountry])

    const showInContextButton = isSyncFeatureEnabled(FeatureFlag.CROWDIN_IN_CONTEXT, selectedCountry)

    const handleLanguageChange = (locale: SupportedLocale) => {
        resetCaptchaChallenge()
        if (selectedCountry === GetAllowedCountriesCountriesEnum.Be && startsWith('en', locale)) {
            goToStep(`/${SupportedLocalePath.EN_BE}`)
        } else {
            goToStep(`/${locale.toLowerCase()}`)
        }
    }

    const handleChallengeDismissed = () => {
        resetCaptchaChallenge()
    }

    const handleCountryChange = (country: GetAllowedCountriesCountriesEnum) => {
        if (window.location.pathname === '/') return
        goToStep(`/${DefaultSupportedLocalePath[getSupportedCountryISO2(getAllowedCountryISO2(country))]}`)
    }

    useEffect(() => {
        if (mounted && registrationFormId) {
            goToNextStep()
        }
    }, [goToNextStep, registrationFormId, mounted])

    return isTerminal || !mounted ? (
        <Loader />
    ) : (
        <>
            {showInContextButton && <CrowdinContextButton />}
            <ScreenStyled.Container>
                <Captcha
                    onChallengeDismiss={handleChallengeDismissed}
                    language={language}
                    onChallengeCompleted={handleCaptchaChallengeCompleted}
                />
                <Title fontWeight={FontWeight.BOLD} i18nKey="country:title" data-testid="title" />
                <Styled.DropdownWrapper>
                    <CountryField
                        onChange={(country) => handleCountryChange(country as GetAllowedCountriesCountriesEnum)}
                        options={allowedCountries}
                        value={selectedCountry}
                    />
                </Styled.DropdownWrapper>
                {selectedCountry === GetAllowedCountriesCountriesEnum.Be && (
                    <Styled.LanguageWrapper>
                        <Styled.DropdownWrapper>
                            <Text textStyle={TextStyle.MEDIUM}>{t('country:language-choice')}</Text>
                            <LanguageField onSelectChange={handleLanguageChange} options={languages} value={language} />
                        </Styled.DropdownWrapper>
                    </Styled.LanguageWrapper>
                )}
                <NextButton pending={isInitializing} onClick={onNextButtonClick} pendingOnClick />
            </ScreenStyled.Container>
        </>
    )
}

export default Country
