import { createAction, createSelector } from '@reduxjs/toolkit'
import { complement, equals, isEmpty, isNil } from 'ramda'

import formsSelectors from '@nickel/forms/redux/selectors'
import { BinaryChoice } from '@nickel/kyc/fields/binaryChoice/types'

import { RootState } from '..'
import { getTaxIdValue } from '../../components/TaxIdentificationNumberForm/utils'
import {
    TAX_IDENTIFICATION_NUMBER,
    TAX_RESIDENCES_INFOS_NAME,
    TAX_RESIDENCES_NAME,
    TAX_RESIDENT_IN_CURRENT_COUNTRY_CHOICE
} from '../../components/TaxResidenceInformations/constant'
import { TaxResidence, TaxResidenceInfos } from '../../components/TaxResidenceInformations/type'
import { getGlobalISO2 } from '../../l10n/config'

import { selectors as countrySelectors } from './country'

/* ------------- Types and Actions ------------- */

export const actions = {
    checkTaxResidenceConsistency: createAction('tax-residence/CHECK_TAX_RESIDENCE_CONSISTENCY'),
    confirmTaxResidenceInCurrentCountry: createAction('tax-residence/CONFIRM_TAX_RESIDENCE_IN_CURRENT_COUNTRY'),
    resetTaxResidenceInCurrentCountry: createAction('tax-residence/RESET_TAX_RESIDENCE_IN_CURRENT_COUNTRY'),
    cancelConfirmationCurrentCountryTaxResidence: createAction(
        'tax-residence/CANCEL_RESIDENCE_IN_CURRENT_COUNTRY_CONFIRMATION'
    ),
    resetOtherTaxResidenceAddress: createAction('tax-residence/RESET_OTHER_TAX_RESIDENCE_ADDRESS')
}

/* ------------- Selectors ------------- */

const getTaxResidenceCountriesState = (pathName?: string) => {
    const basePath = pathName ? `${pathName}.` : ''
    const fullPath = `${basePath}${TAX_RESIDENCES_INFOS_NAME}`
    return createSelector(
        [formsSelectors.getFieldValue<TaxResidenceInfos | TaxResidence[] | string>(fullPath)],
        (taxResidencesInfos) => {
            if (Array.isArray(taxResidencesInfos)) return taxResidencesInfos ?? [{ country: getGlobalISO2() }]
            if (typeof taxResidencesInfos === 'string' || typeof taxResidencesInfos?.taxResidences === 'string')
                return []
            return taxResidencesInfos?.taxResidences && taxResidencesInfos?.taxResidences?.length > 0
                ? taxResidencesInfos?.taxResidences
                : [{ country: getGlobalISO2() }]
        }
    )
}

const getTaxResidencesInfosState = (pathName?: string) => {
    const basePath = pathName ? `${pathName}.` : ''
    const fullPath = `${basePath}${TAX_RESIDENCES_INFOS_NAME}`
    return createSelector([formsSelectors.getFieldValue<TaxResidenceInfos>(fullPath)], (taxResidencesInfosState) => {
        if (!taxResidencesInfosState || !taxResidencesInfosState.taxResidences) return { taxResidences: [] }
        return taxResidencesInfosState
    })
}

const toTaxResidencePayload = ({ country, taxIdentificationNumber }: TaxResidence) => {
    return {
        countryCode: country,
        taxIdentificationNumber:
            country && taxIdentificationNumber ? getTaxIdValue(country, taxIdentificationNumber) : undefined
    }
}

const getTaxResidencesInfos = (pathName?: string) =>
    createSelector(
        [getTaxResidencesInfosState(pathName), getTaxResidenceCountriesState(pathName)],
        (taxResidencesInfosState, taxResidenceCountriesState) => {
            return {
                taxResidences: taxResidenceCountriesState
                    .filter((taxResidence) => complement(isNil)(taxResidence.country))
                    .map(toTaxResidencePayload),
                hasOtherTaxAddress: taxResidencesInfosState?.hasOtherTaxResidenceAddress
                    ? taxResidencesInfosState?.hasOtherTaxResidenceAddress === BinaryChoice.YES
                    : undefined
            }
        }
    )

const getHasMultipleTaxResidence = createSelector([getTaxResidenceCountriesState()], (taxResidenceCountryCodes) => {
    if (isNil(taxResidenceCountryCodes)) return
    return taxResidenceCountryCodes.length > 1
})

const getHasOtherTaxAddressState = (pathName?: string) =>
    createSelector([getTaxResidencesInfosState(pathName)], (taxResidencesInfos) => {
        if (isNil(taxResidencesInfos)) return
        return taxResidencesInfos.hasOtherTaxResidenceAddress
    })

const getTaxResidenceCountryCodes = (pathName?: string) =>
    createSelector([getTaxResidenceCountriesState(pathName)], (state) => {
        return state?.map((taxInfo) => taxInfo.country ?? '').filter((str) => str !== '') ?? []
    })

const getIsNotOnlyTaxResidentInCurrentCountry = (pathName?: string) =>
    createSelector(
        [
            formsSelectors.getFieldValue<BinaryChoice>(
                `${
                    pathName ? `${pathName}.` : ''
                }${TAX_RESIDENCES_INFOS_NAME}.${TAX_RESIDENT_IN_CURRENT_COUNTRY_CHOICE}`
            )
        ],
        (choice) => {
            if (isNil(choice) || isEmpty(choice)) return
            return choice === BinaryChoice.NO
        }
    )

const getIsValidHasOtherTaxAddress = (pathName?: string) =>
    createSelector(
        [getHasOtherTaxAddressState(pathName), getIsNotOnlyTaxResidentInCurrentCountry(pathName)],
        (hasOtherTaxAddressState, isNotOnlyTaxResidentInCurrentCountry) => {
            if (isNil(isNotOnlyTaxResidentInCurrentCountry) || isNil(hasOtherTaxAddressState)) return true
            return !(isNotOnlyTaxResidentInCurrentCountry && !hasOtherTaxAddressState)
        }
    )

const getHasOnlySelectedCurrentCountry = (pathName?: string) =>
    createSelector(
        [getTaxResidenceCountryCodes(pathName), countrySelectors.getCountry],
        (taxResidenceCountryCodes, country) => {
            return equals([country as string])(taxResidenceCountryCodes)
        }
    )

const getAtLeastOneBlankTaxResidenceIdentification = createSelector(
    [getTaxResidenceCountriesState(), countrySelectors.getCountry],
    (taxResidenceCountryCodes, country) =>
        taxResidenceCountryCodes.some(
            (taxInfo) =>
                taxInfo.country !== country &&
                !isNil(taxInfo.country) &&
                (isNil(taxInfo.taxIdentificationNumber) || taxInfo.taxIdentificationNumber === '')
        )
)

const getAtLeastOneBlankTaxResidenceCountry = (pathName?: string) =>
    createSelector([getTaxResidenceCountriesState(pathName)], (taxResidenceCountryCodes) =>
        taxResidenceCountryCodes.some((taxResidenceCountryCode) => !taxResidenceCountryCode.country)
    )

const getHasTaxResidenceCountryCodes = createSelector([getTaxResidenceCountryCodes()], complement(isEmpty))

const getHasNotSelectedEnoughCountries = (pathName?: string) =>
    createSelector(
        [getIsNotOnlyTaxResidentInCurrentCountry(pathName), getHasOnlySelectedCurrentCountry(pathName)],
        (isNotOnlyTaxResidentInCurrentCountry, hasOnlySelectedCurrentCountry) => {
            return isNotOnlyTaxResidentInCurrentCountry && hasOnlySelectedCurrentCountry
        }
    )

const getState = (state: RootState) => state

const getIsTaxIdValid = (index: number, pathName?: string) => {
    const basePath = pathName ? `${pathName}.` : ''
    return formsSelectors.getFieldIsValid(
        `${basePath}${TAX_RESIDENCES_INFOS_NAME}.${TAX_RESIDENCES_NAME}.${index}.${TAX_IDENTIFICATION_NUMBER}`
    )
}

const getIsTaxIdsValid = (pathName?: string) =>
    createSelector([getTaxResidenceCountryCodes(pathName), getState], (countryCodes, state) => {
        const validators: boolean[] = []
        countryCodes.forEach((v, index) => {
            if (v !== getGlobalISO2()) validators.push(getIsTaxIdValid(index, pathName)(state))
        })
        return !validators.includes(false)
    })

const isTaxResidenceValid = (pathName?: string) =>
    createSelector(
        [
            getIsNotOnlyTaxResidentInCurrentCountry(pathName),
            formsSelectors.getFieldIsValid(
                `${pathName ? `${pathName}.` : ''}${TAX_RESIDENCES_INFOS_NAME}.${TAX_RESIDENCES_NAME}`
            ),
            getHasOnlySelectedCurrentCountry(pathName),
            getIsValidHasOtherTaxAddress(pathName),
            getAtLeastOneBlankTaxResidenceCountry(pathName),
            getIsTaxIdsValid(pathName)
        ],
        (
            isNotOnlyTaxResidentInCurrentCountry,
            isValid,
            hasOnlySelectedCurrentCountry,
            isValidHasOtherTaxAddress,
            atLeastOneBlankTaxResidenceCountry,
            isTaxIdsValid
        ) => {
            if (isNil(isNotOnlyTaxResidentInCurrentCountry) || atLeastOneBlankTaxResidenceCountry) {
                return false
            }
            if (hasOnlySelectedCurrentCountry) {
                return true
            }
            return (
                (!isNotOnlyTaxResidentInCurrentCountry || (isValid && isValidHasOtherTaxAddress && isTaxIdsValid)) ??
                false
            )
        }
    )

export const selectors = {
    getHasNotSelectedEnoughCountries,
    getHasTaxResidenceCountryCodes,
    getIsNotOnlyTaxResidentInCurrentCountry,
    isTaxResidenceValid,
    getAtLeastOneBlankTaxResidenceIdentification,
    getTaxResidencesInfosState,
    getTaxResidencesInfos,
    getTaxResidenceCountriesState,
    getHasMultipleTaxResidence
}
