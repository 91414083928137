import Styled from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint } from '@nickel/foundations/tokens/layout'

import { Orientation } from '../../hooks/useOrientation'

const Container = Styled.div<{ visible: boolean; orientation: Orientation }>`
    display:none;

    @media(min-width: ${Breakpoint.MEDIUM}) {
        border-right: 1px solid ${References.REF_NEUTRAL_N20};
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-wrap: wrap;
        height: 100%;
        margin-left: 0;
        max-width: 17em;
        padding: 6em 2em;
        transition: 0.2s all ease-in-out;
        width: 100%;
        z-index: 0;

        ${({ visible }) =>
            !visible &&
            `
            flex-grow: 0;
            margin-left: -16em;
        `}
    }
    ${({ orientation }) =>
        orientation === 'LANDSCAPE' &&
        `
        @media(max-height: 480px) {
          display:none;
        }
        `}
`

export default {
    Container
}
