import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { getCountryByISO2 } from '@nickel/l10n/utils'

import {
    COUNTRIES_ACCEPTED_WITHOUT_TAX_RESIDENCE_NUMBER_DECLARATION,
    NON_AELE_COUNTRIES_WITH_TAX_RESIDENCE_NUMBER_DECLARATION
} from '../../screens/TaxIdentificationNumber/constants'

export const getIsNIFNeeded = (
    ISO2: SupportedCountryISO2,
    country: string,
    numberOfDeclaredTaxResidences: number
): boolean => {
    const isTaxResidenceSameAsRegistrationCountry = ISO2 === country

    const isNIFNeededForRegistrationCountry = !(
        isTaxResidenceSameAsRegistrationCountry &&
        COUNTRIES_ACCEPTED_WITHOUT_TAX_RESIDENCE_NUMBER_DECLARATION.includes(country)
    )

    const isNIFNeededForDERegistrationCountry =
        isTaxResidenceSameAsRegistrationCountry &&
        SupportedCountryISO2.DE === country &&
        COUNTRIES_ACCEPTED_WITHOUT_TAX_RESIDENCE_NUMBER_DECLARATION.includes(country) &&
        numberOfDeclaredTaxResidences > 1

    const isNIFNeededForNonAeleCountry =
        ISO2 === SupportedCountryISO2.PT &&
        (getCountryByISO2(country)?.isUEOrAELE ||
            NON_AELE_COUNTRIES_WITH_TAX_RESIDENCE_NUMBER_DECLARATION.includes(country))

    return isNIFNeededForRegistrationCountry || isNIFNeededForNonAeleCountry || isNIFNeededForDERegistrationCountry
}

export const isTaxResidenceWhichTriggersBlockingAEOIInconsistency = (
    ISO2: SupportedCountryISO2,
    country: string,
    numberOfDeclaredTaxResidences: number
): boolean => {
    if (country === SupportedCountryISO2.DE && ISO2 === country) return false
    return getIsNIFNeeded(ISO2, country, numberOfDeclaredTaxResidences)
}

const TAX_IDENTIFICATION_NUMBER_MASK: Record<string, RegExp[]> = {
    AT: [/^\d{2}-\d{3}\/\d{4}$/],
    DK: [/^\d{6}-\d{4}$/],
    IS: [/^\d{6}-?\d{4}$/],
    CZ: [/^\d{6}\/\d{3,4}$/],
    SK: [/^\d{6}\/\d{3,4}$/]
}

export const getTaxIdValue = (country: string, value: string) => {
    if (value && country && TAX_IDENTIFICATION_NUMBER_MASK[country]) {
        return value.replace(/[-/]/g, '')
    }
    return value
}

export const isTaxIdWithInvalidMask = (country: string, value: string) => {
    if (country && value && TAX_IDENTIFICATION_NUMBER_MASK[country]) {
        return !TAX_IDENTIFICATION_NUMBER_MASK[country].some((mask) => mask.test(value))
    }
    return false
}
