import { all } from 'ramda'

import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'
import { getCountryByISO2 } from '@nickel/l10n/utils'
import { isTrue } from '@nickel/utils/lib/common'
import { PLATFORM } from '@nickel/utils/lib/web'

import { Role } from '../constants/roles'
import { Step } from '../constants/steps'
import { TaxResidencesInfo } from '../redux/taxResidence'
import { CardReceptionModeEnum } from '../screens/CardReceptionMode/types'
import { COUNTRIES_ACCEPTED_WITHOUT_TAX_RESIDENCE_NUMBER_DECLARATION } from '../screens/TaxIdentificationNumber/constants'
import { GetAllowedCountriesCountriesEnum } from '../services'

import { FeatureFlag, isSyncFeatureEnabled } from './features'
import { ConfigStep } from './types'

const getIdentitySteps = (reopeningStatus: 'opening' | 'reopening'): ConfigStep[] => {
    const reopening = reopeningStatus === 'reopening'
    const hiddenFromStepper = Boolean(reopening)

    return [
        {
            hiddenFromStepper,
            name: Step.IDENTITY,
            reopening,
            substeps: [
                {
                    component: 'IdentityChoice',
                    hiddenFromStepper,
                    name: Step.CHOICE,
                    registrationType: RegistrationType.ADULTE,
                    platform: PLATFORM.TERMINAL,
                    unprotected: true
                },
                {
                    component: 'IdentityChoice',
                    hiddenFromStepper,
                    name: Step.CHOICE_GUARDIAN,
                    platform: PLATFORM.TERMINAL,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                    role: Role.GUARDIAN,
                    unprotected: true
                },
                {
                    component: 'IdentityDocument',
                    hiddenFromStepper,
                    name: Step.DOCUMENT,
                    platform: PLATFORM.TERMINAL,
                    registrationType: RegistrationType.ADULTE
                },
                {
                    component: 'IdentityDocument',
                    name: Step.DOCUMENT_GUARDIAN,
                    platform: PLATFORM.TERMINAL,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                    role: Role.GUARDIAN
                },
                {
                    component: 'LivenessVideo',
                    hiddenFromStepper,
                    name: Step.LIVENESS_VIDEO,
                    platform: PLATFORM.WEB,
                    registrationType: RegistrationType.ADULTE,
                    cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE
                },
                {
                    component: 'Civil',
                    name: Step.CIVILITY_CONFIRMATION,
                    registrationType: RegistrationType.ADULTE,
                    cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
                },
                {
                    component: 'BirthDataConfirmation',
                    name: Step.BIRTH_DATA_CONFIRMATION,
                    registrationType: RegistrationType.ADULTE,
                    cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
                },
                {
                    component: 'Liveness',
                    hiddenFromStepper,
                    name: Step.LIVENESS,
                    registrationType: RegistrationType.ADULTE,
                    role: Role.ADULT,
                    cardReceptionMode: CardReceptionModeEnum.CLASSIC as const
                },
                {
                    component: 'Liveness',
                    hiddenFromStepper,
                    name: Step.LIVENESS_GUARDIAN,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                    role: Role.GUARDIAN
                },
                {
                    component: 'Liveness',
                    hiddenFromStepper,
                    name: Step.LIVENESS_CHILD,
                    platform: PLATFORM.WEB,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                    role: Role.CHILD
                },
                {
                    component: 'Liveness',
                    hiddenFromStepper,
                    name: Step.LIVENESS_CHILD,
                    platform: PLATFORM.TERMINAL_WEB,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                    role: Role.CHILD
                },
                {
                    component: 'IdentityChoice',
                    hiddenFromStepper,
                    name: Step.CHOICE_CHILD,
                    platform: PLATFORM.TERMINAL,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                    role: Role.CHILD
                },
                {
                    component: 'IdentityDocument',
                    name: Step.DOCUMENT_CHILD,
                    platform: PLATFORM.TERMINAL,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                    role: Role.CHILD
                },
                {
                    component: 'FamilyRegister',
                    hiddenFromStepper,
                    name: Step.FAMILY_REGISTER,
                    registrationType: RegistrationType.COMPTE_NICKEL_JEUNE
                }
            ]
        }
    ]
}

const getReopeningSteps = (country: GetAllowedCountriesCountriesEnum): ConfigStep[] => {
    const isReopeningManualEnabled = isSyncFeatureEnabled(FeatureFlag.REOPENING_MANUAL, country)
    const isReopeningEnabled = isSyncFeatureEnabled(FeatureFlag.REOPENING, country)
    if (!isReopeningManualEnabled && !isReopeningEnabled) return []

    const steps: ConfigStep[] = []

    if (isReopeningManualEnabled) {
        steps.push({
            component: 'Reopening',
            hiddenFromStepper: true,
            name: Step.REOPENING,
            registrationType: RegistrationType.ADULTE,
            unprotected: true
        })
    } else if (isReopeningEnabled) {
        steps.push(
            {
                component: 'ReopeningChoice',
                hiddenFromStepper: true,
                name: Step.REOPENING_CHOICE,
                registrationType: RegistrationType.ADULTE,
                unprotected: true,
                possibleNextSteps: [
                    'ReopeningInfo',
                    'Liveness',
                    isSyncFeatureEnabled(FeatureFlag.FULL_ONLINE, country) ? 'CardReceptionMode' : ''
                ]
            },
            {
                name: Step.REOPENING_IDENTIFICATION,
                registrationType: RegistrationType.ADULTE,
                reopening: true,
                substeps: [
                    {
                        component: 'ReopeningInfo',
                        hiddenFromStepper: true,
                        name: Step.REOPENING_INFO,
                        unprotected: true
                    },
                    {
                        component: 'ReopeningMatchMulty',
                        hiddenFromStepper: true,
                        name: Step.REOPENING_MATCH_MULTY,
                        reopeningMatch: 'multy' as const,
                        unprotected: true
                    },
                    {
                        component: 'ReopeningMatchZero',
                        hiddenFromStepper: true,
                        name: Step.REOPENING_MATCH_ZERO,
                        reopeningMatch: 'zero' as const,
                        unprotected: true
                    },
                    {
                        component: 'ReopeningMatchOne',
                        hiddenFromStepper: true,
                        name: Step.REOPENING_MATCH_ONE,
                        reopeningMatch: 'one' as const,
                        unprotected: true
                    },
                    {
                        component: 'CustomerSupport',
                        hiddenFromStepper: true,
                        name: Step.CUSTOMER_SUPPORT,
                        reopeningMatch: 'failure' as const,
                        unprotected: true
                    }
                ]
            },
            ...getIdentitySteps('reopening'),
            {
                component: 'ReopeningKyc',
                hiddenFromStepper: true,
                name: Step.REOPENING_KYC,
                reopening: true
            },
            {
                hiddenFromStepper: true,
                name: Step.SIGNING,
                reopening: true,
                substeps: [
                    {
                        component: 'TermsAndConditions',
                        hiddenFromStepper: true,
                        name: Step.TERMS_AND_CONDITIONS,
                        cardReceptionMode: CardReceptionModeEnum.CLASSIC as const
                    },
                    {
                        component: 'Phone',
                        hiddenFromStepper: true,
                        name: Step.PHONE
                    },
                    {
                        component: 'SMSChallenge',
                        hiddenFromStepper: true,
                        name: Step.SMS_CHALLENGE
                    }
                ]
            },
            {
                component: 'Final',
                hiddenFromStepper: true,
                name: Step.FINAL,
                reopening: true,
                substeps: [
                    {
                        component: 'Final',
                        name: Step.FINAL_STEP
                    }
                ]
            }
        )
    }

    return steps
}
const getFiscalAddressStep = (country: GetAllowedCountriesCountriesEnum): ConfigStep[] => {
    return country === GetAllowedCountriesCountriesEnum.Pt
        ? [
              {
                  component: 'FiscalAddress',
                  name: Step.FISCAL_ADDRESS,
                  registrationType: RegistrationType.ADULTE,
                  role: Role.ADULT
              }
          ]
        : []
}
const getCardCustomizationStep = (isBasic: boolean): ConfigStep[] => {
    return !isBasic
        ? [
              {
                  component: 'BankCardCustomization',
                  hiddenFromStepper: true,
                  name: Step.BANK_CARD_CUSTOMIZATION,
                  platform: PLATFORM.WEB,
                  registrationType: RegistrationType.ADULTE
              }
          ]
        : []
}
const getBiometricConsentStep = (tagReceptionMode: CardReceptionModeEnum): ConfigStep[] => {
    return tagReceptionMode === CardReceptionModeEnum.FULL_ONLINE
        ? [
              {
                  component: 'BiometricConsent',
                  hiddenFromStepper: true,
                  name: Step.BIOMETRIC_CONSENT,
                  platform: PLATFORM.WEB,
                  registrationType: RegistrationType.ADULTE,
                  possibleNextSteps: ['Liveness', 'BankCardType', 'MandatoryEmail']
              }
          ]
        : [
              {
                  component: 'BiometricConsent',
                  hiddenFromStepper: true,
                  name: Step.BIOMETRIC_CONSENT,
                  platform: PLATFORM.WEB,
                  registrationType: RegistrationType.ADULTE,
                  cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const,
                  possibleNextSteps: ['Liveness', 'BankCardType', 'MandatoryEmail']
              }
          ]
}

const getMandatoryEmailStep = (): ConfigStep => {
    return {
        component: 'MandatoryEmail',
        hiddenFromStepper: true,
        name: Step.MANDATORY_EMAIL,
        platform: PLATFORM.WEB,
        cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const,
        mailAlreadySet: false
    }
}

const getCardSteps = (
    country: GetAllowedCountriesCountriesEnum,
    isBasic: boolean,
    tagReceptionMode: CardReceptionModeEnum
): ConfigStep[] =>
    isSyncFeatureEnabled(FeatureFlag.FULL_ONLINE, country)
        ? [
              {
                  component: 'CardReceptionMode',
                  hiddenFromStepper: true,
                  name: Step.CARD_RECEPTION_MODE,
                  platform: PLATFORM.WEB,
                  registrationType: RegistrationType.ADULTE
              },
              ...getBiometricConsentStep(tagReceptionMode),
              getMandatoryEmailStep(),
              {
                  component: 'BankCardType',
                  hiddenFromStepper: true,
                  name: Step.BANK_CARD_TYPE,
                  platform: PLATFORM.WEB,
                  registrationType: RegistrationType.ADULTE
              },
              ...getCardCustomizationStep(isBasic)
          ]
        : []

const getTaxIdentificationStep = (
    country: GetAllowedCountriesCountriesEnum,
    taxResidences: TaxResidencesInfo['taxResidences']
): ConfigStep[] => {
    const isAllowedToContinueWithoutTaxResidenceNumberDeclaration =
        taxResidences?.length === 1 &&
        taxResidences.some(
            ({ country: countryTaxResidence }) =>
                country === countryTaxResidence &&
                COUNTRIES_ACCEPTED_WITHOUT_TAX_RESIDENCE_NUMBER_DECLARATION.includes(countryTaxResidence)
        )

    return isAllowedToContinueWithoutTaxResidenceNumberDeclaration && isSyncFeatureEnabled(FeatureFlag.AEOI, country)
        ? []
        : [
              {
                  component: 'TaxIdentificationNumber',
                  name: Step.TAX_IDENTIFICATION_NUMBER
              }
          ]
}
const getIsTaxResidenceInProgressStep = (
    country: GetAllowedCountriesCountriesEnum,
    taxResidences: TaxResidencesInfo['taxResidences']
): { component: string; name: Step }[] => {
    let isStartedObtainingTaxResidencyNecessary = false

    if (country !== GetAllowedCountriesCountriesEnum.Pt) return []

    if (
        taxResidences.length > 1 ||
        taxResidences.find((residence) => residence.country !== GetAllowedCountriesCountriesEnum.Pt)
    ) {
        const taxResidencesWithoutRegistrationCountry = taxResidences.filter((tax) => tax.country !== country)
        isStartedObtainingTaxResidencyNecessary = all(isTrue)(
            taxResidencesWithoutRegistrationCountry.map(
                ({ country: countryTaxResidence }) => !getCountryByISO2(countryTaxResidence)?.isUEOrAELE
            )
        )
    }

    return isStartedObtainingTaxResidencyNecessary
        ? [
              {
                  component: 'TaxResidenceInProgress',
                  name: Step.TAX_RESIDENCE_IN_PROGRESS
              }
          ]
        : []
}

const getSteps = (
    country: GetAllowedCountriesCountriesEnum,
    isBasic: boolean,
    tagReceptionMode: CardReceptionModeEnum,
    taxResidences: TaxResidencesInfo['taxResidences']
): ConfigStep[] => [
    {
        component: 'Country',
        hiddenFromStepper: true,
        name: Step.COUNTRY,
        unprotected: true
    },
    {
        component: 'AllElements',
        hiddenFromStepper: true,
        name: Step.ALL_ELEMENTS,
        unprotected: true
    },
    {
        component: 'Mail',
        hiddenFromStepper: true,
        name: Step.MAIL,
        unprotected: true
    },
    ...(isSyncFeatureEnabled(FeatureFlag.CNJ, country)
        ? [
              {
                  component: 'RegistrationType',
                  hiddenFromStepper: true,
                  name: Step.REGISTRATION_TYPE,
                  platform: PLATFORM.WEB,
                  unprotected: true,
                  possibleNextSteps: ['Guardian', 'Reopening']
              },
              {
                  component: 'RegistrationType',
                  hiddenFromStepper: true,
                  name: Step.REGISTRATION_TYPE,
                  platform: PLATFORM.TERMINAL_WEB,
                  unprotected: true
              },
              {
                  component: 'Guardian',
                  hiddenFromStepper: true,
                  name: Step.GUARDIAN,
                  registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                  unprotected: true
              }
          ]
        : []),
    {
        component: 'Barcode',
        hiddenFromStepper: true,
        name: Step.BARCODE,
        platform: PLATFORM.TERMINAL,
        unprotected: true
    },
    ...getReopeningSteps(country),
    ...getCardSteps(country, isBasic, tagReceptionMode),
    {
        component: 'TermsAndConditionsEid',
        hiddenFromStepper: true,
        name: Step.TERMS_AND_CONDITIONS_EID,
        cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
    },
    {
        component: 'PrivacyPolicyEid',
        hiddenFromStepper: true,
        name: Step.PRIVACY_POLICY_EID,
        cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
    },
    {
        component: 'TermsAndConditions',
        hiddenFromStepper: true,
        name: Step.TERMS_AND_CONDITIONS,
        cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
    },
    ...getIdentitySteps('opening'),
    {
        name: Step.PERSONAL_DATA,
        substeps: [
            {
                component: 'FamilyStatus',
                name: Step.FAMILY_STATUS
            },
            {
                component: 'PropertyStatus',
                name: Step.PROPERTY_STATUS
            },
            {
                component: 'Address',
                name: Step.ADDRESS,
                registrationType: RegistrationType.ADULTE
            },
            {
                component: 'Address',
                name: Step.ADDRESS_GUARDIAN,
                registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                role: Role.GUARDIAN
            },
            {
                component: 'Address',
                name: Step.ADDRESS_CHILD,
                registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                role: Role.CHILD
            }
        ]
    },
    {
        name: Step.FISCAL,
        substeps: [
            ...(isSyncFeatureEnabled(FeatureFlag.AEOI, country)
                ? [
                      {
                          component: 'TaxResidenceCountry',
                          name: Step.TAX_RESIDENCE_COUNTRY,
                          possibleNextSteps: ['TAX_IDENTIFICATION_NUMBER', 'TAX_RESIDENCE_ADDRESS']
                      },
                      ...getIsTaxResidenceInProgressStep(country, taxResidences),
                      ...getTaxIdentificationStep(country, taxResidences),
                      {
                          component: 'TaxResidenceAddress',
                          name: Step.TAX_RESIDENCE_ADDRESS
                      }
                  ]
                : [
                      ...getFiscalAddressStep(country),
                      {
                          component: 'TaxResidence',
                          name: Step.TAX_RESIDENCE
                      }
                  ]),
            {
                component: 'UsPerson',
                name: Step.US_PERSON
            },
            {
                component: 'PrivateLifeCertification',
                name: Step.PRIVATE_LIFE_CERTIFICATION
            }
        ]
    },
    {
        name: Step.BANK,
        substeps: [
            {
                component: 'AccountType',
                name: Step.ACCOUNT_TYPE
            },
            {
                component: 'AccountUse',
                name: Step.ACCOUNT_USE
            },
            {
                component: 'Employment',
                name: Step.EMPLOYMENT
            },
            {
                component: 'Financial',
                name: Step.FINANCIAL
            },
            {
                component: 'Civil',
                name: Step.CIVILITY_CONFIRMATION,
                registrationType: RegistrationType.ADULTE,
                cardReceptionMode: CardReceptionModeEnum.CLASSIC as const
            },
            {
                component: 'BirthDataConfirmation',
                name: Step.BIRTH_DATA_CONFIRMATION,
                registrationType: RegistrationType.ADULTE,
                cardReceptionMode: CardReceptionModeEnum.CLASSIC as const
            },
            {
                component: 'Civil',
                name: Step.CIVILITY_CONFIRMATION_GUARDIAN,
                registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                role: Role.GUARDIAN
            },
            {
                component: 'BirthDataConfirmation',
                name: Step.BIRTH_DATA_CONFIRMATION_GUARDIAN,
                registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                role: Role.GUARDIAN
            },
            {
                component: 'Civil',
                name: Step.CIVILITY_CONFIRMATION_CHILD,
                registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                role: Role.CHILD
            },
            {
                component: 'BirthDataConfirmation',
                name: Step.BIRTH_DATA_CONFIRMATION_CHILD,
                registrationType: RegistrationType.COMPTE_NICKEL_JEUNE,
                role: Role.CHILD
            }
        ]
    },
    {
        name: Step.SIGNING,
        substeps: [
            {
                component: 'TermsAndConditions',
                name: Step.TERMS_AND_CONDITIONS
            },
            {
                component: 'Phone',
                name: Step.PHONE
            },
            {
                component: 'SMSChallenge',
                name: Step.SMS_CHALLENGE
            }
        ],
        cardReceptionMode: CardReceptionModeEnum.CLASSIC as const
    },
    {
        name: Step.FINAL,
        substeps: [
            {
                component: 'OrderSummary',
                name: Step.ORDER_SUMMARY,
                platform: PLATFORM.WEB,
                registrationType: RegistrationType.ADULTE,
                cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
            },
            {
                component: 'Payment',
                name: Step.PAYMENT,
                platform: PLATFORM.WEB,
                registrationType: RegistrationType.ADULTE,
                cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
            },
            {
                component: 'AccountValidation',
                name: Step.ACCOUNT_VALIDATION,
                platform: PLATFORM.WEB,
                cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
            },
            {
                component: 'AccountSummary',
                name: Step.ACCOUNT_SUMMARY,
                platform: PLATFORM.WEB,
                cardReceptionMode: CardReceptionModeEnum.FULL_ONLINE as const
            },
            {
                component: 'Final',
                name: Step.FINAL_STEP,
                cardReceptionMode: CardReceptionModeEnum.CLASSIC as const
            }
        ]
    }
]

export default getSteps
