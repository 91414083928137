import { pathOr } from 'ramda'
import { useSelector } from 'react-redux'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import { COUNTRY_CODE } from '@nickel/l10n/constants'
import useL10n from '@nickel/l10n/useL10n'
import { isMobile, isTerminal, isTerminalWeb, PLATFORM } from '@nickel/utils/lib/web'

import { getRebornHomePage } from '../i18n'
import { getAllowedCountryISO2, getSupportedCountryISO2 } from '../l10n/utils'
import { DefaultSupportedLocalePath } from '../navigation/types'
import { selectors as countrySelectors } from '../redux/form/country'
import { GetAllowedCountriesCountriesEnum, PostalAddressView } from '../services'

export const rootRoute = (() => {
    const country = window.REACT_APP_COUNTRY_CODE ? window.REACT_APP_COUNTRY_CODE.toLowerCase() : COUNTRY_CODE.ES
    const locale = DefaultSupportedLocalePath[getSupportedCountryISO2(getAllowedCountryISO2(country))]
    if (window.REACT_APP_PLATFORM !== PLATFORM.TERMINAL && window.REACT_APP_PLATFORM !== PLATFORM.TERMINAL_WEB)
        return `/${locale}`
    return `/${locale}?platform=${window.REACT_APP_PLATFORM.toLowerCase()}`
})()

export const getRootRoute = (() => (params: unknown) => {
    const country = window.REACT_APP_COUNTRY_CODE ? window.REACT_APP_COUNTRY_CODE.toLowerCase() : COUNTRY_CODE.ES
    const locale = DefaultSupportedLocalePath[getSupportedCountryISO2(getAllowedCountryISO2(country))]
    if (window.REACT_APP_PLATFORM !== PLATFORM.TERMINAL && window.REACT_APP_PLATFORM !== PLATFORM.TERMINAL_WEB)
        return `/${locale}?${params}`
    return `/${locale}?platform=${window.REACT_APP_PLATFORM.toLowerCase()}&${params}`
})()

const BELGIUM_HOME_LOCATION = {
    [SupportedLocale.EN_US]: window.REACT_APP_HOME_WEB_LOCATION_EN_BE,
    [SupportedLocale.NL_BE]: window.REACT_APP_HOME_WEB_LOCATION_NL_BE
}

export const useHomeLocation = () => {
    const { l } = useL10n()
    const locale = useSelector(countrySelectors.getLanguage)
    if (isTerminalWeb) return getRebornHomePage()
    return isTerminal
        ? window.REACT_APP_HOME_BORNE_LOCATION
        : l({
              ES: window.REACT_APP_HOME_WEB_LOCATION_ES,
              PT: window.REACT_APP_HOME_WEB_LOCATION_PT,
              DE: window.REACT_APP_HOME_WEB_LOCATION_DE,
              BE: pathOr(window.REACT_APP_HOME_WEB_LOCATION_FR_BE, [locale], BELGIUM_HOME_LOCATION),
              default: window.REACT_APP_HOME_WEB_LOCATION_FR
          })
}

export const isDefined = <T>(value?: T | null): value is T => value !== undefined && value !== null

export const isArray = <T>(v: T | T[]): v is T[] => {
    return typeof v === 'object' && (v as []).length !== undefined
}

export const getDeviceType = (): PLATFORM => {
    if (isTerminal) {
        return PLATFORM.TERMINAL
    }
    if (isTerminalWeb) {
        return PLATFORM.TERMINAL_WEB
    }
    if (isMobile) {
        return PLATFORM.MOBILE
    }
    return PLATFORM.WEB
}

export const getExpiryDate = () => {
    const date = new Date()
    date.setFullYear(new Date().getFullYear() + 3)
    let month: string | number = date.getMonth() + 1
    month = month < 10 ? `0${month}` : month
    const year = date.getFullYear().toString().substring(2)
    return `${month}/${year}`
}

export const getFormatedPrice = (price: number, locale: SupportedLocale = SupportedLocale.FR_FR, currency = 'EUR') => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price)
}

const join = (delimiter: string, values: (string | undefined)[] = []) => {
    return values.filter((value) => value !== undefined).join(delimiter)
}

export const getLocalizedAddress = (
    address: PostalAddressView,
    country: GetAllowedCountriesCountriesEnum = GetAllowedCountriesCountriesEnum.Fr
) => {
    let streetName = ''
    switch (country) {
        case GetAllowedCountriesCountriesEnum.Be:
        case GetAllowedCountriesCountriesEnum.De:
        case GetAllowedCountriesCountriesEnum.Pt: {
            streetName = join(' ', [address.streetName, address.streetNumber])
            break
        }
        case GetAllowedCountriesCountriesEnum.Es: {
            streetName = join(', ', [address.streetName, address.streetNumber])
            break
        }
        default: {
            streetName = join(' ', [address.streetNumber, address.streetName])
            break
        }
    }
    return {
        streetName,
        additionalInfo: address.additionalAddress,
        zipCode: address.zipCode,
        place: address.place,
        countryCode: address.countryCode
    }
}
