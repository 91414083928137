import { addLibTranslations as addLibCoreUiTranslations } from '@nickel/core-ui/lib/i18n'
import i18n, { addTranslations, Dictionnary, getCurrentLng } from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import { addLibTranslations as addLibKycTranslations } from '@nickel/kyc/lib/i18n'
import { addLibTranslations as addLibL10nTranslations } from '@nickel/l10n/lib/i18n'
import { addLibTranslations as addLibOnboardingTranslations } from '@nickel/onboarding/lib/i18n'
import { addLibTranslation as addLibStimulusTranslations } from '@nickel/stimulus'
import { addLibTranslations as addLibUiTranslations } from '@nickel/ui/lib/i18n'
import { addLibTranslation as addLibUtilsTranslation } from '@nickel/utils/lib/i18n'

import { SupportedLocalePath } from '../navigation/types'
import { GetAllowedCountriesCountriesEnum } from '../services'

import achUG from './languages/ach_UG'
import deDE from './languages/de_DE'
import enUS from './languages/en_US'
import esES from './languages/es_ES'
import frBE from './languages/fr_BE'
import frFR from './languages/fr_FR'
import nlBE from './languages/nl_BE'
import ptPT from './languages/pt_PT'
import root from './languages/root'
import { Locale } from './types'

export const dictionnary: Dictionnary = {
    [SupportedLocale.EN_GB]: enUS,
    [SupportedLocale.EN_US]: enUS,
    [SupportedLocale.ES_ES]: esES,
    [SupportedLocale.FR_BE]: frBE,
    [SupportedLocale.FR_FR]: frFR,
    [SupportedLocale.NL_BE]: nlBE,
    [SupportedLocale.PT_PT]: ptPT,
    [SupportedLocale.ACH_UG]: achUG,
    [SupportedLocale.DE_DE]: deDE,
    root
}

export const LANGUAGES_LABELS: Record<SupportedLocale, string> = {
    [SupportedLocale.EN_GB]: 'English',
    [SupportedLocale.EN_US]: 'English',
    [SupportedLocale.ES_ES]: 'Español',
    [SupportedLocale.FR_BE]: 'Français',
    [SupportedLocale.FR_FR]: 'Français',
    [SupportedLocale.NL_BE]: 'Nederlands',
    [SupportedLocale.PT_PT]: 'Português',
    [SupportedLocale.DE_DE]: 'Deutsch',
    [SupportedLocale.ACH_UG]: 'Acholi',
    root: 'Français'
}

export function setGlobalLanguage(lang: SupportedLocale) {
    localStorage.setItem('REACT_APP_LANGUAGE', lang)
    window.REACT_APP_LANGUAGE = lang
    i18n.changeLanguage(lang)
}

i18n.init({
    fallbackLng: 'root',
    // useless after updating i18next >= v23
    // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
    returnNull: false,
    interpolation: {
        escapeValue: false,
        format: (value, format) => (format === 'lowercase' ? value.toLowerCase() : value)
    }
})

if (window.REACT_APP_LANGUAGE) setGlobalLanguage(window.REACT_APP_LANGUAGE as SupportedLocale)

addTranslations(dictionnary)
addLibKycTranslations()
addLibL10nTranslations()
addLibStimulusTranslations()
addLibUiTranslations()
addLibCoreUiTranslations()
addLibUtilsTranslation()
addLibOnboardingTranslations()

export const getLocaleFromPathName = (): Locale => {
    const [language, country] = window.location.pathname.substring(1).split('-')
    const locale = `${language.toLowerCase()}-${country.toUpperCase()}`
    if (Object.values(SupportedLocale).indexOf(locale as SupportedLocale) === -1) {
        if (country.toUpperCase() === GetAllowedCountriesCountriesEnum.Be && language === 'en') {
            return {
                language: SupportedLocale.EN_US,
                country: country.toUpperCase() as GetAllowedCountriesCountriesEnum
            }
        }
    }
    return {
        language: locale as SupportedLocale,
        country: country.toUpperCase() as GetAllowedCountriesCountriesEnum
    }
}

export const getRebornHomePage = (): string => {
    if (getCurrentLng() === SupportedLocale.EN_US) {
        return window.REACT_APP_HOME_BORNE_WEB_LOCATION + SupportedLocalePath.EN_BE
    }
    return window.REACT_APP_HOME_BORNE_WEB_LOCATION + getCurrentLng()
}

export default i18n
