import styled from 'styled-components'

import Button from '@nickel/core-ui/components/Button'
import { Spacing } from '@nickel/foundations/tokens/layout'

const NextButton = styled(Button)<{ fullScreen: boolean }>`
    margin: auto;
    ${({ fullScreen }) =>
        fullScreen &&
        ` width : 110%;
          transform: translate(-5%);
          span {
           margin: auto;
          }`}
`
const NextButtonContainer = styled.div`
    padding: ${Spacing.XLARGE} ${Spacing.LARGE};
    text-align: center;
    > :not(:first-child) {
        margin-top: ${Spacing.LARGE};
    }
`
export default {
    NextButton,
    NextButtonContainer
}
