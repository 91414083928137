import styled, { css } from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { FontSize } from '@nickel/foundations/tokens/typography'

const circleIndicator = css`
    stroke: ${References.REF_BRAND_B100};
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 250ms linear;
`

type CircleProps = {
    indicator?: boolean
    strokeDasharray: number
    strokeDashoffset: number
}

const Circle = styled.circle.attrs(({ strokeDasharray, strokeDashoffset = 0 }: CircleProps) => ({
    cx: '50%',
    cy: '50%',
    r: 85,
    strokeDasharray,
    strokeDashoffset,
    strokeWidth: 10
}))<CircleProps>`
    fill: transparent;
    stroke: ${References.REF_NEUTRAL_N20};

    ${(props) => props.indicator && circleIndicator}
`

const Container = styled.div`
    position: relative;
`

const Label = styled.span`
    font-size: ${FontSize.XXXLARGE};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`

export default {
    Circle,
    Container,
    Label
}
